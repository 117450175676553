<script setup>
  import { useRouter } from "vue-router"

  import { setCurrentViewContext } from "@/utils/GlobalState"
  import { changePassword } from "@/services/Account"
  import { getOwnPasswordSchema } from "@/schemas/Account"
  import { showFlashMessage } from "@/utils/GlobalState"
  import { gettext } from "@/utils/Translation"
  
  const { $gettext } = gettext
  const router = useRouter()

  function submitForm(data) {
    this.submitAttrs.inputClass = "button is-loading"
    this.submitLabel = $gettext("Loading")
    const { old_password, new_password } = data
    changePassword({ old_password, new_password })
      .then(() => {
        showFlashMessage({ msg: $gettext("Your changes have been saved."), class: "is-success" })
        router.push({ name: "SiteList" })
      })
      .catch(error => {
        let msg = $gettext("Something went wrong. Please try again.")
        if (error.name === "BAD_REQUEST") {
          if ("oldPassword" in error.data) {
            msg = $gettext("Your old password was incorrect. Please try again.")
          }
        }
        showFlashMessage({ msg, class: "is-danger" })
        this.submitAttrs.inputClass = "button"
        this.submitLabel = $gettext("Save")
      })
  }

  setCurrentViewContext()
</script>

<template>
  <article>
    <h1 class="title">
      {{ $gettext("Change my password") }}
    </h1>
    <FormKit
      type="form"
      :submit-label="$gettext('Save')"
      :submit-attrs="{ inputClass: 'button is-primary' }"
      @submit="submitForm"
    >
      <FormKitSchema :schema="getOwnPasswordSchema()" />
    </FormKit>
  </article>
</template>
